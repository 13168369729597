<template>
  <footer>
    <div class="container">
      <nav>
        <ul>
          <li><a href="#">售后服务</a></li>
          <li><a href="#">企业入驻</a></li>
          <li><a href="#">帮助中心</a></li>
          <li><a href="#">在线客服</a></li>
          <li><a href="#">物流配送</a></li>
          <li><a href="#">商家入驻</a></li>
          <li><a href="#">招商中心</a></li>
          <li><a href="#">关于我们</a></li>
        </ul>
      </nav>
      <div class="copyright">
        <p>深圳云方信息技术有限公司 版权所有 2021-2022</p>
        <p>ICP证号：粤ICP备2021140192号-1</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerTmpl",
};
</script>

<style lang="less" scoped>
footer {
  padding: 48px 0;
  background: #f3f5fc;
  min-width: 1200px;

  .container {
    position: relative;
    margin: 0 auto;
    width: 1200px !important;
  }

  nav {
    height: 20px;

    ul {
      padding: 0;
      list-style: none;
      font-size: 0;

      li {
        display: inline-block;

        a {
          position: relative;
          top: 1px;
          display: block;
          font-size: 14px;
          height: 18px;
          line-height: 18px;
          color: #666666;
          width: 150px;
          text-align: center;

          &::after {
            content: "";
            display: block;
            width: 1px;
            height: 18px;
            position: absolute;
            top: 0;
            right: 0.5px;
            background: #ccc;
          }
        }
      }

      li:last-child a::after {
        display: none;
      }
    }
  }

  .copyright {
    margin: 48px auto 0;
    font-size: 12px;
    color: #666666;
    text-align: center;
    line-height: 28px;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
