import axios from "axios";
import store from "../store/index";
import router from "../route/index";
import md5 from "js-md5";

const api = axios.create({
  baseURL:
    process.env.VUE_APP_MODE === "dev" ? "/api" : "//yfapi.szyunfang.com/",
  timeout: 6000,
});

api.all = axios.all;
api.spread = axios.spread;

const err = (error) => {
  console.log(error);
  if (error.response) {
    const data = error.response.data;
    if (error.response.status === 500) {
      store.dispatch("alert", {
        icon: "error",
        msg: data.error || error.message,
        info: data.message || "",
        timer: 0,
      });
      return;
    }

    if (error.response.status === 401) {
      store.dispatch("clearUserData");
      router.push("/signin");
      return;
    }

    console.log("接口错误：", data);
    if (data.code === 10001 || data.code === 5106 || data.code === 401) {
      store.dispatch("clearUserData");
      router.push("/signin");
    } else {
      store.dispatch("alert", {
        icon: "error",
        msg: data.message,
        info: data.data || "错误代码：[" + data.code + "]",
        timer: 0,
      });
    }
  }
  return Promise.reject(error);
};

api.err = err;

api.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token")
    ? sessionStorage.getItem("token")
    : "init";
  config.headers["Content-Type"] = "application/json;charset=utf-8";
  config.headers.token = token;
  if (!config.data) {
    config.data = {};
  }
  config.headers.sign = md5(
    token + JSON.stringify(config.data) + "GyQZ0FmSpvskLiDMm3IZQQ=="
  );
  return config;
}, err);

api.interceptors.response.use((response) => {
  // console.log(response.config.url, ":", response);
  const res = response.data;
  if (res.code !== 200) {
    if (res.code === 5106 || res.code === 401) {
      store.dispatch("clearUserData");
    }
    if (response.config.error === undefined) return err({ response });
    else return Promise.reject(response);
  } else {
    return res.data;
  }
}, err);

export default api;
