export default {
  common: {
    classify: "/mall/index/hotSearchRecommend",
    province: "/mall/getAllProvince",
    city: "/mall/getAllCityByPid",
    district: "/mall/getAllDistrictByCid",
    shoppingCart: "/mall/myShoppingCart",
    signup: "/mall/memberCenter/register",
    signin: "/mall/memberCenter/checklogin",
    signout: "/mall/memberCenter/outLogin",
    forgot: "/mall/memberCenter/resetPassword",
  },
  sms: {
    send: "/mall/sendVerifyCode",
    verify: "/mall/verifyCode",
  },
  home: {
    hotSale: "/mall/index/getHotSale",
    floors: "/mall/index/getFloor",
  },
  search: {
    products: "/mall/searchProduct",
    filters: "/mall/searchCondition",
  },
  center: {
    index: "/mall/memberCenter/myIndex",
    info: "/mall/memberCenter/myInfo",
    safe: "/mall/memberCenter/securityLevel",
    editInfo: "/mall/memberCenter/modifyMyInfo",
    orders: "/mall/memberCenter/myOrder",
    orders_count: "/mall/order/selectOrderStatusAll",
    favors: "/mall/memberCenter/myFollow",
    addFavor: "/mall/memberCenter/saveMyFollow",
    removeFavor: "/mall/memberCenter/cancelMyFollow",
    address: "/mall/memberCenter/queryMyAddress",
    defaultAddress: "/mall/memberCenter/defaultMyAddress",
    addAddress: "/mall/memberCenter/addMyAddress",
    editAddress: "/mall/memberCenter/modifyMyAddress",
    reviews: "/mall/comment/selectGoodsCommentByMe",
    unreview: "/mall/comment/readyGoodsComment",
    addReviews: "/mall/comment/addGoodsComment",
  },
  order: {
    submit: "/mall/order/submitOrder",
    detail: "/mall/order/orderdetails",
  },
  pay: {
    weixin: "/pay/createOrder",
    weixinCheck: "/pay/queryOrderNoState",
    alipay: "/pay/toAlipay",
  },
  goods: {
    detail: "/mall/productDetail/getProductDetail",
    breadcrumb: "/mall/productDetail/getCrumbs",
    province: "/mall/getAllProvince",
    city: "/mall/getAllCityByPid",
    district: "/mall/getAllDistrictByCid",
    favor: "/mall/memberCenter/saveMyFollow",
    unfavor: "/mall/memberCenter/cancelMyFollow",
  },
  cart: {
    add: "/mall/addProductToShopCar",
    removeAll: "/mall/delShoppingCart",
    del: "/mall/delShoppingCartById",
    num: "/mall/changeShoppingCartByNum",
  },

  orderStatus(s) {
    return {
      0: "待付款",
      1: "待发货",
      2: "待收货",
      // 3: "已经收货",
      4: "已取消",
      // 7: "申请退货",
      // 8: "店铺同意退货",
      // 9: "店铺拒绝退货",
      10: "已完成",
      // 11: "订单结束",
      // 12: "同意退款",
      // 13: "拒绝退款",
      // 14: "已提交退货审核",
      // 15: "已提交退款审核",
      // 16: "收货失败",
      // 17: "已退款",
      // 18: "申请退款 退款成功",
      // 20: "平台同意退货",
      // 21: "平台拒绝退货",
      // 22: "确认待收货",
      23: "待付款",
    }[s];
  },

  orderOperates(s) {
    return {
      0: [{ code: "pay", name: "付款" }],
      1: [{ code: "ship", name: "查看物流" }],
      2: [
        { code: "delivery", name: "确认收货" },
        { code: "ship", name: "查看物流" },
      ],
      // 3: "已经收货",
      4: [],
      // 7: "申请退货",
      // 8: "店铺同意退货",
      // 9: "店铺拒绝退货",
      10: [{ code: "ship", name: "查看物流" }],
      // 11: "订单结束",
      // 12: "同意退款",
      // 13: "拒绝退款",
      // 14: "已提交退货审核",
      // 15: "已提交退款审核",
      // 16: "收货失败",
      // 17: "已退款",
      // 18: "申请退款 退款成功",
      // 20: "平台同意退货",
      // 21: "平台拒绝退货",
      // 22: "确认待收货",
      23: [{ code: "pay", name: "付款" }],
    }[s];
  },
};
